import { graphql } from 'gatsby';
import React, { FC, Fragment, useState } from 'react';
import theme from '../constants/theme';
import {
    CaseTilesFragment,
    ClientQuoteFragment,
    CTAFragment,
    SolutionPageFragment,
    SolutionPageQuery,
} from '../entities/operationResults';
import withDefaultTransition from '../hocs/withDefaultTransition';
import { Section } from './atoms/Section';
import CTA from './molecules/CTA';
import CaseTiles from './organisms/CaseTiles';
import ClientQuote from './organisms/ClientQuote';
import Conclusion from './organisms/Conclusion';
import Features from './organisms/Features';
import Footer from './organisms/Footer';
import Hero from './organisms/Hero';
import Intro from './organisms/Intro';
import Smart from './organisms/Smart';
import Supportive from './organisms/Supportive';
import TeamMemberQuote from './organisms/TeamMemberQuote';
import Trust from './organisms/Trust';
import AnimationRevealCircle from './molecules/AnimationRevealCircle';
import { WindowLocation } from '@reach/router';
import Meta from './atoms/Meta';
import { removeTags } from '../utils/removeTags';

interface Props {
    data: SolutionPageQuery;
    // tslint:disable-next-line:no-any
    location: WindowLocation & { state: any };
}
export const query = graphql`
    query SolutionPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...SolutionPageFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment SolutionPageFragment on Craft_Solution {
        id
        title
        hero {
            ...HeroFragment
        }
        intro {
            ...IntroFragment
        }
        featuresTitle
        features {
            ...FeatureFragment
        }
        quote {
            ...ClientQuoteFragment
        }
        teamMemberQuote {
            ...TeamMemberQuoteFragment
        }
        trust {
            ...TrustFragment
        }
        cases {
            ...CaseTilesFragment
        }
        callToAction {
            ...CTAFragment
        }
        smart {
            ...SmartFragment
        }
        supportive {
            ...SupportiveFragment
        }
        conclusion {
            ...ConclusionFragment
        }
        directContact {
            ...DirectContactFragment
        }
        tint {
            hex
        }
        seo {
            ...MetaFragment
        }
    }
`;

const SolutionPage: FC<Props> = ({ data, location }) => {
    const entry = data.craft.entry as SolutionPageFragment;

    const hero = entry.hero && entry.hero[0];
    const quote = entry.quote && entry.quote[0];
    const teamMemberQuote = entry.teamMemberQuote && entry.teamMemberQuote[0];
    const trust = entry.trust && entry.trust[0];
    const intro = entry.intro && entry.intro[0];
    const { featuresTitle, features } = entry;
    const ctaList = (entry.callToAction || []) as CTAFragment[];
    const smart = entry.smart && entry.smart[0];
    const supportive = entry.supportive && entry.supportive[0];
    const conclusion = entry.conclusion && entry.conclusion[0];
    const footer = entry.directContact && entry.directContact[0];
    const [revealed, setRevealed] = useState(false); // reveal animation done, ready for offscreen rendering
    const isRevealed = !location.state || !location.state.animateIn || revealed;
    const meta = entry.seo && entry.seo[0];

    return (
        <Fragment>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : entry.title}
                description={
                    meta && meta.seoDescription
                        ? meta.seoDescription
                        : hero && hero.description && removeTags(hero.description.content)
                }
                image={meta && meta.seoImage ? meta.seoImage : null}
            />

            {!isRevealed && (
                <AnimationRevealCircle
                    color={(entry.tint && entry.tint.hex) || '#ffffff'}
                    readyToReveal={true}
                    onFinish={() => {
                        setRevealed(true);
                    }}
                />
            )}
            <Section>
                {hero && <Hero data={hero} ratio={759 / 1168} />}
                {intro && <Intro data={intro} variant="solution" />}
            </Section>
            {isRevealed && (
                <Fragment>
                    {features && <Features title={featuresTitle} data={features} />}
                    {quote && <ClientQuote data={quote as ClientQuoteFragment} />}
                    <Section background={theme.colors.shark} fontColor={theme.colors.white}>
                        {trust && <Trust trust={trust} />}
                        <CaseTiles data={(entry.cases || []).filter((tile): tile is CaseTilesFragment => !!tile)} />
                        {ctaList[0] && <CTA sourceId={entry.id} data={ctaList[0]} withMargin />}
                    </Section>
                    <Section paddingLeft={3}>
                        {smart && <Smart data={smart} />}
                        {teamMemberQuote && <TeamMemberQuote data={teamMemberQuote} />}
                        {supportive && <Supportive data={supportive} />}
                        {ctaList[1] && <CTA sourceId={entry.id} data={ctaList[1]} />}
                    </Section>
                    {conclusion && (
                        <Section>
                            <Conclusion data={conclusion} />
                        </Section>
                    )}
                    <Section variant={'footer'}>{footer && <Footer entryId={entry.id} data={footer} />}</Section>
                </Fragment>
            )}
        </Fragment>
    );
};

export default withDefaultTransition(SolutionPage);
